import { lazy } from 'react';
import {
  AppstoreOutlined,
  PlusCircleOutlined,
  SnippetsOutlined,
  HighlightOutlined
} from '@ant-design/icons';

const Category = lazy(() => import('./pages/Category'));
const AddOn = lazy(() => import('./pages/AddOn'));
const AddOnType = lazy(() => import('./pages/AddOnType'));
const Fabric = lazy(() => import('./pages/Fabric'));

const routes = [
  {
    key: Math.random(),
    path: '/',
    component: Category,
    icon: <AppstoreOutlined />,
    label: 'Category'
  },
  {
    key: Math.random(),
    path: '/addOn',
    component: AddOn,
    icon: <PlusCircleOutlined />,
    label: 'Add on'
  },
  {
    key: Math.random(),
    path: '/addOnType',
    component: AddOnType,
    icon: <SnippetsOutlined />,
    label: 'Add on Type'
  },
  {
    key: Math.random(),
    path: '/fabric',
    component: Fabric,
    icon: <HighlightOutlined />,
    label: 'Fabric'
  }
];
export default routes;
