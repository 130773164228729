import React, { Suspense, useState } from 'react';
import { Routes, Route, NavLink, useLocation } from 'react-router-dom';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import {
  Layout,
  Menu,
  theme,
  ConfigProvider,
  Row,
  Popconfirm,
  Button
} from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';

import routes from './routes';
import Login from './pages/Login';
import './assets/styles/global.scss';
import clientLogo from './assets/images/logo.png';
import { localStorageName } from './configs/constants';
const { Header, Sider, Content } = Layout;

const App = () => {
  let { pathname } = useLocation();
  pathname = pathname.replace('/', '');

  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer }
  } = theme.useToken();

  // if (pathname == 'login') return <Login />;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#203D31'
        }
      }}
    >
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            overflow: 'auto',
            height: '100vh'
          }}
        >
          <div className="logo">
            <img
              style={{
                height: '100px',
                width: '90%',
                margin: '20px auto',
                objectFit: 'contain'
              }}
              src={clientLogo}
              alt="client logo"
            />
          </div>
          <Menu theme="dark" mode="inline">
            {routes.map((item, i) => (
              <Menu.Item
                key={i}
                title={item.label}
                className={pathname == item.path ? 'active' : ''}
              >
                <NavLink to={item.path}>
                  <span>{item.icon}</span>
                  {'  '}
                  <span
                    style={{
                      visibility: collapsed ? 'hidden' : 'visible'
                    }}
                  >
                    {item.label}
                  </span>
                </NavLink>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header
            style={{
              padding: 0,
              background: colorBgContainer
            }}
          >
            <Row
              justify="space-between"
              align="middle"
              style={{
                height: '100%',
                width: '95%'
              }}
            >
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: 'trigger',
                  onClick: () => setCollapsed(!collapsed)
                }
              )}
              <Popconfirm
                title="Are you Sure?"
                okText="Yes"
                onConfirm={() => {
                  localStorage.removeItem(localStorageName);
                  window.location.href = '/login';
                }}
                cancelText="No"
              >
                <Button
                  type="danger"
                  htmlType="button"
                  style={{
                    background: 'red',
                    color: 'white'
                  }}
                  icon={<PoweroffOutlined />}
                >
                  Logout
                </Button>
              </Popconfirm>
            </Row>
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
              background: colorBgContainer
            }}
          >
            <Routes>
              {routes.map((route) => (
                <Route
                  path={route.path}
                  key={route.key}
                  element={
                    <Suspense fallback={<>...</>}>
                      <route.component />
                    </Suspense>
                  }
                />
              ))}
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default App;
